body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-content {
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box {
  background: linear-gradient(rgba(245, 192, 94, 0.753), transparent);
  background-color: rgba(218, 148, 20, 0.521); /*this your primary color*/
}

.subcat {
  background-color: #eee;
}

.subcat:hover {
  background-color: grey;
  color: white;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.cartbtn {
  color: rgb(214, 213, 213);
}

.cats-wrapper {
  padding-left: 15px;
  height: 260px;
  overflow-x: hidden;
  display: flex;
}

.cats-wrapper:hover {
  overflow-x: scroll;
}

.footer-btn {
  cursor: pointer;
  color: rgb(182, 175, 175);
  padding: 8px;
  font-size: 16px;
  text-decoration: underline;
}

.footer-btn:hover {
  color: white;
}

.cat-item {
  cursor: pointer;
  height: 170px;
  width: 170px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: solid 1px rgb(92, 92, 92);
}

.cat-item:hover {
  border: solid 2px #000;
}

.side-cat {
  color: #000;
  font-size: medium;
}

.side-cat:hover {
  color: rgb(212, 110, 14);
}

.cats-wrapper::-webkit-scrollbar {
  width: 0;
  height: 5;
}

.cartbtn:hover {
  color: rgb(255, 255, 255);
}

.button {
  border-radius: 4px;
  background-color: #000;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  padding: 7px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.order-card {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 0px 7px grey;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.status-circle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: #eee;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.g-sign-in-button {
  margin: 10px;
  display: inline-block;
  width: 240px;
  height: 50px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}

.g-sign-in-button:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
  background-color: #3367d6;
  transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.g-sign-in-button img {
  width: 18px;
  height: 18px;
}

.g-sign-in-button .logo-wrapper {
  padding: 15px;
  background: #fff;
  width: 48px;
  height: 100%;
  border-radius: 1px;
  display: inline-block;
}

.g-sign-in-button .text-container {
  font-family: Roboto, arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.21px;
  font-size: 16px;
  line-height: 48px;
  vertical-align: top;
  border: none;
  display: inline-block;
  text-align: center;
  width: 180px;
}

label[for="file-input"] {
  display: block;
  margin-bottom: 1em;
  font-size: 1em;
  color: #fff;
  opacity: 0.9;
  font-weight: bold;
}

input[type="file"] {
  cursor: pointer !important;
}
input[type="file"]::-webkit-file-upload-button {
  border: none;
  margin-left: 5px;
  padding: 5px 12px;
  background: #188542;
  color: #fff;
  font-size: 1em;
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 20px;
}
input[type="file"]::-ms-browse {
  border: none;
  padding: 5px 12px;
  background: #9e2baf;
  color: #fff;
  font-size: 1em;
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 20px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 35%;
  right: 35%;
  top: 35%;
  bottom: 35%;
  margin: auto;
  background: white;
}
